<template>
  <div class="course">
    <div v-if="$screenWidth > 1200">
      <img :src="ImgUrl" alt="">
      <h1>小聂活动</h1>
      <div class="courses">
        <div class="personal_tab_pane_content_pane2_item"
          v-for="(item,index) in dataList"
          :key="index"
          @click="toVideo(item)"
          >
          <router-link :to="{path:'/share/download',query:{id:item.uuid}}" target="_blank">
            <el-image :src="item.imgurl2" :alt="item.name"></el-image>
          </router-link>
        </div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10,15,20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>
    <div v-else class="mobile">
      <el-image :src="ImgUrl"
        style="height: 30vh;"
        fit="cover"></el-image>
      <h3>小聂活动</h3>
      <div class="courses2">
        <div class="personal_tab_pane_content_pane2_item"
          v-for="(item,index) in dataList"
          :key="index"
          @click="toVideo(item)"
          >
          <el-image :src="item.imgurl2" :alt="item.name" fit="cover" ></el-image>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Course',
  data(){
    return{
      dataList:[],
      currentPage:1,
      pageSize:10,
      total:0,
      ImgUrl:''
    }
  },
  created() {
    this.getCourse()
    this.getHomeImgList()
  },
  methods:{
    //获取首页轮播图
    async getHomeImgList() {
      let res = await this.$http.get('/slideshow/getSlideshow', {
        params: {
          slideshowType: 2
        }
      })

      this.ImgUrl = res.data.data[0].imgurl;
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val
      this.getCourse()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val
      this.getCourse()
    },
    async getCourse(){
      // let res = await this.$http.get('/official/lesson/allLessons')
      let params = {
        // target:'ALL',
        pageNum:this.currentPage,
        pageSize:this.pageSize
      }
      let res = []
      if(this.$screenWidth > 1200){
        res = await this.$http.get('/official/lesson/allLessons',{params:params})
      }else{
        res = await this.$http.get('/official/lesson/allLessons')
      }
      console.log(res)
      this.total = res.data.total
      this.dataList = res.data.data
    },
    async toVideo(item){

       // this.$router.push({
       //   path: '/share/download'
       // })

      // let res = await this.$http.get('/official/lesson/catalog',{
      //     params:{uuid:item.uuid}
      // })
      // console.log(res)
      // this.$http.get('/official/lesson/catalog',{
      //     params:{uuid:item.uuid}
      // }).then(res=>{
      //   this.$router.push({name:'/course/video',params:{uuid:item.uuid,px:1}})
      // }).catch(err=>{
      //   console.log(err.response.data.message)
      //   this.$message.error(err.response.data.message)
      //   // if(res.status == 500){
      //   //   this.$router.push({name:'/course/video',params:{uuid:item.uuid,px:1}})
      //   // }
      // })
    }
  }
}
</script>

<style lang="scss" scoped>
  .course{
    img{
      width: 100%;
    }
    h1{
      color: #707070;
      margin: 20px 0;
    }
    .courses{
      // width: 70%;
      // display: flex;
      // flex-wrap: wrap;
      // justify-content: space-between;
      // margin: 0 auto;
      // .item{
      //   width: 30%;
      //   background-color: pink;
      // }
      // &::after{
      //   content: '';
      //   width: 30%;
      // }
      width: 60%;
      margin: 0 auto;
      text-align: left;

      .personal_tab_pane_content_pane2_item{
        display: inline-block;
        width: 40%;
        margin: 20px 0;
        margin-right: 5%;
        //box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
        border: 1px solid #ebeef5;
        box-sizing: border-box;
        cursor: pointer;
        text-decoration: none;
        &:link{
          color: #5f6464;
        }
        &:visited{
          color: #5f6464;
        }
        &:active{
          color: #5f6464;
        }
        &:hover{
          transform: translateY(-2%);
          box-shadow: 1px 1px 10px 2px #CCC;
          transition: .3s;
          color: #5f6464;
        }
        &:nth-of-type(3n){
          // margin: 0;
        }
        .el-image{
          width: 100%;
          // height: 200px;
          border-radius: 10px;
        }
        .personal_tab_pane_content_pane2_item_bottom{
          padding: 20px;
          padding-top: 0;
          p{
          margin: 16px 0;
          &:nth-of-type(1){
            font-size: 24px;
            font-weight: bolder;
          }
          &:nth-of-type(2){
            color: #7b7b7b;
          }
        }
        span{
          display: inline-block;
          width: 50px;
          height: 30px;
          border-radius: 15px;
          margin-right: 10px;
          box-sizing: border-box;
          color: #fff;
          padding: 5px;
          text-align: center;
          &:nth-of-type(1){
            background-color: #FEBB34;
          }
          &:last-child{
            background-color: #FF6666;
          }
        }
        }

      }
    }
    .mobile{
      h3{
        // margin: 10px 0;
        margin-top: 20px;
      }
      .courses2{
        .personal_tab_pane_content_pane2_item{
          display: inline-block;
          // width: 30%;
          margin: 2px 5vw;
          // margin-right: 5%;
          // box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
          border: 1px solid #ebeef5;
          box-sizing: border-box;
          cursor: pointer;
          text-decoration: none;
          &:link{
            color: #5f6464;
          }
          &:visited{
            color: #5f6464;
          }
          &:active{
            color: #5f6464;
          }
          &:hover{
            transform: translateY(-2%);
            box-shadow: 1px 1px 10px 2px #CCC;
            transition: .3s;
            color: #5f6464;
          }
          &:nth-of-type(3n){
            margin: 0;
          }
          .el-image{
            width: 100%;
            // height: 200px;
            border-radius: 10px;
          }
          .personal_tab_pane_content_pane2_item_bottom{
            text-align: left;
            padding: 20px;
            padding-top: 0;
            p{
            margin: 16px 0;
            &:nth-of-type(1){
              font-size: 24px;
              font-weight: bolder;
            }
            &:nth-of-type(2){
              color: #7b7b7b;
            }
          }
          span{
            display: inline-block;
            width: 50px;
            height: 30px;
            border-radius: 15px;
            margin-right: 10px;
            box-sizing: border-box;
            color: #fff;
            padding: 5px;
            text-align: center;
            &:nth-of-type(1){
              background-color: #FEBB34;
            }
            &:last-child{
              background-color: #FF6666;
            }
          }
          }

        }
      }

    }
  }
</style>
