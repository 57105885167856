<template>
	<div>
		<el-row class="main" type="flex" justify="center">
			<el-col :span="screenWidth>699?10:23">
				<div id="artcle-info">
					<h2 class="text-center"><strong>{{thisList.name}}</strong></h2>
					<!-- 描述：文章信息 -->
					<div class="text-center timeAndView">
						<span class="article-time">
							<i class="el-icon-time"></i>
							更新于：<span>{{thisList.updateTime}}</span>
						</span>
					</div>
				</div>
				<hr />
				<div id="artcle-content" style="text-align: initial;">
					<div v-html="thisList.detail"></div>
				</div>
				<div v-if="screenWidth<699" class="item">
					<div class="item" style="width: 100%;">
						<el-col :span="screenWidth>699?4:0" class="hidden-sm-and-down" id="side" style="width: 100%;">
							<div class="item" style="width: 100%;">
								<el-card class="box-card">
									<div slot="header" class="d-flex align-items-center">
										<span>扫码手机查看</span>
										<el-button class="ml-auto" style="padding: 3px 0" type="text">
											<router-link to="/apply" tag="span"></router-link>
										</el-button>
									</div>
									<div class="item">
										<img class="imgys"
											:src="thisList.qrcode">
									</div>
								</el-card>
							</div>
						</el-col>
					</div>
				</div>
				<div id="statement" style="text-align: initial;width: 100%;">
					<div class="item">声明：仅供参考，具体详情，请就地考察!</div>
				</div>
			</el-col>
			<el-col :span="screenWidth>699?4:0" class="hidden-sm-and-down" id="side">
				<div class="item" style="width: 100%;padding: 0 2%;">
					<el-col :span="screenWidth>699?4:0" class="hidden-sm-and-down" id="side" style="width: 100%;">
						<div class="item" style="width: 100%;">
							<el-card class="box-card">
								<div slot="header" class="d-flex align-items-center">
									<span>扫码手机查看</span>
									<el-button class="ml-auto" style="padding: 3px 0" type="text">
										<router-link to="/apply" tag="span"></router-link>
									</el-button>
								</div>
								<div class="item">
									<img class="imgys"
										:src="thisList.qrcode">
								</div>
							</el-card>
						</div>
					</el-col>
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	// import tag from '../../components/tag'
	// import friend from '../../components/friend'
	export default {
		name: 'article',
		components: {
			// tag,
			// friend
		},
		data() {
			return {
				screenWidth: document.body.clientWidth, // 屏幕宽
				screeHeight: document.body.clientHeight, // 屏幕高
				thisList: {},
				id: ''
			}
		},
		created() {

		},
		mounted: function() {
			if (this.$route.query.id != undefined) {
				this.id = this.$route.query.id
				console.log(this.id)
			}
			this.getCoachList();
		},
		methods: {
			//获取数据
			async getCoachList() {
				let res = await this.$http.get('/basePropaganda/get', {
					params: {
						id: this.id,
					}
				})
				//       console.log('数据', res.data.data);
				// categoryId: 3
				// createBy: "1"
				// createTime: "2021-09-03 16:39:54"
				// deleted: 0
				// detail: "<p>云浮基地</p>"
				// id: 4
				// image: "https://abc.xnrun.com/image/default/FD28C56D5D954F75A499AF27CDB00501-6-2.png"
				// logo: "https://abc.xnrun.com/image/default/7C3DA4A5A3A44EE98C644633F983E46C-6-2.png"
				// managementId: "2"
				// name: "云浮基地"
				// qrcode: "https://abc.xnrun.com/image/default/281DE5126EC64E408D5A8222294F6F5C-6-2.png"
				// slideshow: "https://abc.xnrun.com/image/default/EFA5BE1A136447F1AA8BF127264AB701-6-2.png,"
				// updateBy: "1"
				// updateTime: "2021-09-03 16:39:54"
				// video: "5"
				res.data.data.detail = res.data.data.detail.replace(
					/<iframe class="ql-video" frameborder="0" allowfullscreen="true"/g,
					'<video style="width: 100%;" controls><source ');
				res.data.data.detail = res.data.data.detail.replace(/<\/iframe>/g, ' type="video/mp4"></video> ');
				res.data.data.detail = res.data.data.detail.replace(/<img/g,
					'<img style="width: auto;height: auto; max-width: 100%;max-height: 100%;" ');
				this.thisList = res.data.data;
			}
		}
	}
</script>

<style scoped>
	#side .item {
		margin-bottom: 30px;
	}

	.art-item {
		width: 100%;
		margin-bottom: 10px;
		position: relative;
	}

	#artcle-info {
		padding: 20px;
		background-color: rgba(85, 170, 0, 0.4);
		margin-bottom: 40px;
		border-radius: 10px;
	}

	#artcle-info .abstract {
		color: #ffffff;
		border-left: 3px solid #F56C6C;
		padding: 10px;
		background-color: rgba(126, 129, 135, 0.3);
	}

	#artcle-info .timeAndView {
		padding: 20px;
		line-height: 30px;
		font-size: 16px;
		color: #ffffff;
	}

	pre.has {
		color: #ffffff;
		background-color: rgba(0, 0, 0, 0.8);
	}

	img.has {
		width: 100%;
	}

	#statement {
		border-left: 3px solid #55aa00;
		padding: 20px;
		background-color: #d9dbe1;
	}

	.box-card .item a {
		text-decoration: none;
		/*color: #F56C6C;*/
	}

	.imgys {
		width: 100%;
		height: auto;
		min-width: 100%;
	}

	.box-card span {
		font-weight: bold;
	}

	.card-icon {
		width: 20px;
		height: 20px;
		margin-right: 10px;
	}
</style>
