<template>
	<div class="download">
		<div class="width">
			<div class="team">
				<!-- <h1>训练营各地战队</h1> -->
				<lory class="items" :options="{ enableMouseEvents: true}" v-if="team.length>0">
					<item class="item" v-for="(item,index) in team" :key="index">
						<router-link :to="{ path:'/baseInfo', query: { id: item.id }}" target="_blank"
							class="art-title">
							<img :src="item.image" alt="">
							<h2>{{item.name}}</h2>
						</router-link>
						<!-- <p>队员数量：{{item.memberNum}}</p> -->
						<!-- <el-button type="info" @click="onEnterTeam(item.address)">进入战队</el-button> -->
					</item>
					<item class="item">
					</item>
					<prev slot="actions"></prev>
					<next slot="actions"></next>
				</lory>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		Lory,
		Item,
		Prev,
		Next
	} from 'vue-lory'
	export default {
		components: {
			Lory,
			Item,
			Prev,
			Next
		},
		data() {
			return {
				team: []
			}
		},
		created() {
			this.getTeam()
		},
		methods: {
			async getTeam() {
				let res = await this.$http.get('/basePropaganda/listBasePropaganda')
				console.log({
					team: res
				})
				this.team = res.data.data
			},
			go3D() {
				console.log('?????')
				window.open('/3Dmap', "_blank");
			},
		}
	}
</script>

<style lang="scss" scoped>
	.download {
		width: 100vw;
		min-height: 40vw;
		// background-image: url('https://abc.xnrun.com/image/default/8C276565795B4140A2B8135FE08613CF-6-2.png');
		// background-image: url('https://abc.xnrun.com/image/default/F8D7D6B00971449DAE5FEC18571C9F0C-6-2.png');
		// background-image: url('https://abc.xnrun.com/image/default/712DDF61EA72452A87AE8FAB70CB2F3A-6-2.png');//01
		background-image: url('https://abc.xnrun.com/image/default/38B93624C3524954B6D5020ACADD31FC-6-2.png');//01
		background-size: 100% 100%;
		// background-position: left bottom;
	}

	.width {
		width: 60vw;
		margin: 0 19vw;
		padding-top: 20vw;
	}

	.team {

		h1 {
			color: rgba(80, 80, 80, 1);
			font-size: 34px;
			font-weight: bold;
		}

		@media screen and (max-width: 768px) {
			h1 {
				color: rgba(80, 80, 80, 1);
				font-size: 16px;
				font-weight: bold;
			}
		}

		.el-button {
			background-color: #fafafa;
			border-color: #f50;
			color: #f50;

			&:hover {
				background-color: #f50;
				color: #FFFFFF;
			}
		}

		::v-deep .items {
			// display: flex;
			position: relative;

			.item {
				// flex: 1;
				width: 50%;
				padding: 30px;

				@media screen and (max-width: 1200px) {
					width: 40%;
					padding: 10px;
				}

				img {
					width: 176px;
					height: 176px;
					border-radius: 88px;
					border: 1px solid #eee;

					@media screen and (max-width: 1200px) {
						width: 80px;
						height: 80px;
					}
				}

				h2 {
					font-size: 23px;
					margin: 20px;
					color: #fff;
				}

				p {
					color: rgba(166, 166, 166, 1);
					font-size: 20px;
				}

				.el-button {
					font-size: 20px;
				}
			}

			.prev svg {
				transform: translateX(-200%);

				@media screen and (max-width: 1200px) {
					transform: translateX(0%);
				}

				background-color: #e0e0e06e;
				border-radius: 25px;
				width: 50px;
				height: 50px;

				path {
					fill: #fff;
				}
			}

			.next svg {

				transform: translateX(200%);

				@media screen and (max-width: 1200px) {
					transform: translateX(0%);
				}

				background-color: #e0e0e06e;
				border-radius: 25px;
				width: 50px;
				height: 50px;

				path {
					fill: #fff;
				}
			}
		}
	}
</style>
